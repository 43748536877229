import React, { useState } from "react";

const RespirationBrochure = ({setShowForm,show, setShow}) => {
  

  return (
    <div className="container">
      <div className="p_brochure">
        <div style={{ textAlign: "center" }}>
        
        <p
              style={{
                fontSize: "26px",
                color: "#383886",
                fontWeight: "600",
                marginBottom: "0px",
              }}
            >
              Optimizing Respiration 
              <br/>
              for Achieving High Performance
            </p>
            <p style={{ fontSize: "14px", marginBottom: "1.5rem", fontWeight:"bold" }}>
            Includes live demonstrations and practicum work
            </p>
            <p style={{ fontSize: "14px", marginBottom: "1rem",fontWeight:"bold" }}>
          <em>
          A 15-hour live webinar
            <br/>
            December 7 and 8, 08:30 - 16:30 Mountain Time 
          </em>
            </p>
      
        
          <div style={{ padding: "1rem 0" }}>
            <p
              style={{
                fontSize: "14px",
                marginBottom: "10px",
                fontWeight: "600",
                color: "#383886",
              }}
            >
            with Scott Sonnon
            </p>
            <p style={{ fontSize: "14px",margin:"0 auto 0px",width:"70%" }}>
            Mr. Sonnon is the senior principal intelligence analyst and human research protections administrator
who leads the US Department of Energy's human performance analytics and biotechnology innovation.

            </p>
            <p style={{ fontSize: "14px",margin:"0 auto 1.5rem",width:"85%" }}>
He developed the resilience training courses used by the Federal Bureau of Investigation, the Defense Intelligence Agency, the
National Nuclear Security Administration, and elements of the United States Special Operations Command. His research addresses
wearable sensors for identifying interventions for optimizing cognitive and physical performance.
            </p>
            <p
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  fontWeight: "600",
                
                }}
                // onClick={()=>setShowForm(true)}
              >
                Tuition: $550.00
              </p>
            <p
                style={{
                  fontSize: "14px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  color: "#383886",
                  display: "inline",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={()=>setShowForm(true)}
              >
                CLICK HERE TO REGISTER
              </p>
              <p
                style={{
                  fontSize: "13px",
                  marginBottom: "1rem",
                  marginTop: "0px",
                }}
              >
                OR go to{" "}
                <a href="https://e-campus-registration.bp.edu/" target="_blank">
                  www.e-campus.bp.edu
                </a>
              </p>
          </div>
        {!show && (
          <p
            style={{
              fontSize: "14px",
              marginBottom: "2px",
              fontWeight: "600",
              color: "#383886",
              //   display: "inline",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setShow(!show)}
          >
            CLICK HERE TO READ MORE...
          </p>
        )}

        {show && (
                <p
                  style={{
                    fontSize: "14px",
                    marginBottom: "1rem",
                    fontWeight: "600",
                    color: "#383886",
                    //   display: "inline",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShow(!show)}
                >
                  CLICK HERE TO HIDE.
                </p>
              )}
        </div>
        {show && (
          <div style={{ padding: "1rem 0 0" }}>
          
        
            <p
              style={{
                fontSize: "20px",
                fontWeight: "600",
                textAlign: "center",
                marginBottom:"1rem",
                color:"#383886"
              }}
            >
              ABOUT THE WEBINAR
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
            Mr. Sonnon will present an overview of breathing behavior biofeedback relevant to performance training and learning based on
many years of ongoing research and practical applications in high-stress physical and mental environments. Breathing habit
assessment and training for achieving high performance will be demonstrated live with participants who attend with capnometers.
Case reports and data will also be presented.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
            Participants will learn about (1) the role of CO2 in cognitive, emotion, speech, and exertion; (2) the dynamics of breathing during
physical exertion and how it is conventionally assessed in exercise physiology and cardiopulmonary exercise testing; (3) the
continuum of breathing behavioral changes that reflexively occur with increased metabolic demands of exercise; and (4) changes in
baseline breathing behavior due to dysfunctional breathing patterns and how exercise-related breathing reflex can occur
prematurely. 
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
            Participants will learn and practice how to use wearable capnography technology for (1) assessing dysfunctional breathing behavior
during light-to-moderate physical exercise, (2) identifying premature breathing reflex triggers, and (3) self-regulating respiration
within optimal CO2 ranges during light-to-moderate physical exercise. 
            </p>
          
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
            Mr. Sonnon presents the key respiratory biometrics of respiratory feedback instrumentation relevant to breathing behavioral
changes that act as buffering mechanisms for maintaining and restoring acid-base physiological homeostasis, that is, the
chemoregulation of pH balance in body fluids such as blood plasma. He examines how learned breathing behavior triggers,
exacerbates, perpetuates, and causes acid-base physiological instability, known in general as respiratory alkalosis and more
specifically as behavioral hypocapnia (Litchfield 2010). He describes maladaptive compromises that can establish baseline breathing
behavioral patterns that trigger, perpetuate, exacerbate, or cause autonomic reactivity and associated symptomology
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
            Mr. Sonnon presents a theoretical framework for understanding how external insults and internal perceptual, emotional, and
metabolic demands are adaptively compensated for by breathing behavior changes. He explores dynamic physical activity as an
attenuator of acid-base instability. He describes an array of breathing interventions that correct maladaptive breathing behavioral
patterns. He introduces a toolset for physically and cognitively restructuring breathing behavior based on breathing behavior
analysis and educational capnometry (carbon dioxide) technology. 
            </p>
            <p style={{ fontSize: "14px",marginBottom:"3rem" }}>
            Mr. Sonnon discusses learned maladaptive behaviors that compromise compensatory breathing behavior. He reviews respiratory
biofeedback (PCO2 and SaO2) in combination with complementary psychophysiological feedback, e.g., HRV, EEG, and EMG along with
specific breathing interventions that contribute to successful psychophysiological self-regulation. In so doing, he discusses the key
characteristics of breathing behavior change, including ratio, transport, locus, rate, volume, rhythm, and cardiorespiratory
synchronization
            </p>
         
            <div style={{ textAlign: "center",background:"#f9ebeaad",padding:"10px" }}>
            <p
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  fontWeight: "600",
                
                }}
                // onClick={()=>setShowForm(true)}
              >
                Tuition: $550.00
              </p>
            <p
                style={{
                  fontSize: "14px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  color: "#383886",
                  display: "inline",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={()=>setShowForm(true)}
              >
                CLICK HERE TO REGISTER
              </p>
              <p
                style={{
                  fontSize: "13px",
                  marginBottom: "1rem",
                  marginTop: "0px",
                }}
              >
                OR go to{" "}
                <a href="https://e-campus-registration.bp.edu/" target="_blank">
                  www.e-campus.bp.edu
                </a>
              </p>
           
              <p
                style={{
                  fontSize: "12px",
                  marginBottom: "2px",
                  fontWeight: "bold",
                  color: "#383886",
                }}
              >
              Behavioral Breathing Sciences
              </p>
              <p
                style={{
                  fontSize: "13.5px",
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
              >
                PROFESSIONAL SCHOOL OF BEHAVIORAL HEALTH SCIENCES
              </p>
              <p
                style={{
                  fontSize: "15px",
              marginBottom:"0"
                }}
              >
                Professional Diploma, Certificate, and Certification programs
              </p>
              <p
                style={{
                  fontSize: "13px",
                  marginBottom: "2rem",
                //   marginTop: "2rem",
                }}
              >
              
                <a href="mailto:education@bp.edu">education@bp.edu</a>{" "} 307.633.9800
              </p>

              {show && (
                <p
                  style={{
                    fontSize: "14px",
                    marginBottom: "1rem",
                    fontWeight: "600",
                    color: "#383886",
                    //   display: "inline",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShow(!show)}
                >
                  CLICK HERE TO HIDE.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RespirationBrochure;
