import React, { useState } from "react";

const PregencyBrochure = ({setShowForm,show, setShow}) => {


  return (
    <div className="container">
      <div className="p_brochure">
        <div style={{ textAlign: "center" }}>
          <p
            style={{
              fontWeight: "600",
              fontSize: "21px",
              marginBottom: "2px",
            }}
          >
            Professional School of Behavioral Health Sciences
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <p className="links">
              Registration:{" "}
              <a href="https://e-campus-registration.bp.edu/" target="_blank">
                www.e-campus.bp.edu
              </a>
            </p>
            <p className="links">
              Website:{" "}
              <a href="https://www.bp.edu/" target="_blank">
                www.bp.edu
              </a>
            </p>
            <p className="links">
              Website: <a href="mailto:education@bp.edu">education@bp.edu</a>
            </p>
          </div>
          <div style={{ padding: "1rem 0" }}>
            <p style={{ fontSize: "14px", marginBottom: "2px" }}>
              Introducing an Exciting and Important New Webinar
            </p>

            <p
              style={{
                fontSize: "26px",
                color: "#383886",
                fontWeight: "600",
                marginBottom: "2px",
              }}
            >
              Breathing Science for Birth Professionals
            </p>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                marginBottom: "0px",
              }}
            >
              All women during pregnancy can and should learn breathing habits
              that optimize respiration.
            </p>
          </div>
          <div style={{ padding: "1rem 0" }}>
            <p
              style={{
                fontSize: "15.5px",
                marginBottom: "2px",
                fontWeight: "600",
              }}
            >
              <span style={{ color: "red"}}>November 9</span>, Saturday: 09:00
              - 14:00 Mountain Time
            </p>
            <p style={{ fontSize: "12px", marginBottom: "0px" }}>
              Participants earn five hours of Professional Continuing Education
              Credit.
            </p>
          </div>
          <div style={{ padding: "1rem 0" }}>
            <p
              style={{
                fontSize: "14px",
                marginBottom: "10px",
                fontWeight: "600",
                color: "#383886",
              }}
            >
              with Lisa V Kusch and Peter M Litchfield
            </p>
            <p style={{ fontSize: "14px",margin:"0 auto 10px",width:"75%" }}>
              These two leading experts in breathing behavior analysis present a
              myth-busting webinar for birth practitioners, counselors, and
              educators that focuses on the psychophysiology of breathing and
              its role in optimizing respiration during pregnancy, labor, birth,
              and postpartum.
            </p>
            <p
              style={{
                fontSize: "14px",
                marginBottom: "2px",
                fontWeight: "600",
                color: "#383886",
                display: "inline",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={()=>setShowForm(true)}
            >
              CLICK HERE TO REGISTER
            </p>
          </div>
        {!show && (
          <p
            style={{
              fontSize: "14px",
              marginBottom: "2px",
              fontWeight: "600",
              color: "#383886",
              //   display: "inline",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setShow(!show)}
          >
            CLICK HERE TO READ MORE...
          </p>
        )}

        {show && (
                <p
                  style={{
                    fontSize: "14px",
                    marginBottom: "2rem",
                    fontWeight: "600",
                    color: "#383886",
                    //   display: "inline",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShow(!show)}
                >
                  CLICK HERE TO HIDE.
                </p>
              )}
        </div>
        {show && (
          <div style={{ padding: "1rem 0 0" }}>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              Most women during pregnancy learn breathing habits that compromise
              respiration. The effects can be devastating and subtle, immediate
              and long term, misunderstood and mistakenly attributed to
              pregnancy and stress rather than to the effects of learned
              breathing habits. How can this be true? Ms. Kusch and Dr.
              Litchfield, world experts on breathing behavior analysis, take you
              on a wild, exciting, and unexpected tour of what can quickly and
              easily be done about it, CAPNOLEARNING. Read the following for
              some startling practical realities.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              1. All women, while pregnant, to one degree or another suffer from
              carbon dioxide (CO2) deficiency, that is, the physiological
              condition known as hypocapnia. This is an undisputed fact.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              2. Hypocapnia (CO2 deficiency) is caused by overbreathing, that
              is, losing too much CO2 by either taking breaths too rapidly
              and/or that are too large. Retaining the correct concentration of
              CO2 in body fluids such as blood plasma is essential to health and
              performance. This is basic physiology.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              3. Overbreathing is motivated by air hunger, that is, a sense of
              not being able to get enough oxygen, because of pressure on the
              diaphragm and hormonal changes. Based on air hunger pregnant women
              learn breathing habits that compromise respiration. Breathing is
              behavior and it is motivated.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              4. Hypocapnia causes immediate and long-term physiological changes
              of all kinds, including acid-base deregulation, electrolyte
              imbalances, restricted blood flow, muscle contraction, and
              metabolic acidosis in brain cells (neurons). These physiological
              changes and many more are described in textbooks everywhere.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              5. Hypocapnic symptoms and deficits can be devastating,
              misinterpreted, and mistakenly attributed to other causes. These
              effects can be profound and are usually blamed on pregnancy rather
              than on breathing. Surveys suggest that 60% of ambulance runs in
              the largest USA cities are a result of hypocapnia.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              6. Hypocapnia can reduce blood flow in the brain by as much as 50%
              in a matter of seconds. Brain fog is often the result. The ability
              to focus, remember, think, and learn can be severely compromised.
              This may translate into poor work performance attributed to
              pregnancy rather than to breathing habits.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              7. Reduced blood flow in the brain translates into oxygen deficit
              and low blood sugar, often triggering or exacerbating difficult
              emotions, e.g., unexplainable crying, fear, anxiety, frustration,
              anger, and vulnerability. Faulty self-assessment of these symptoms
              may lead to misguided opinions, conclusions, and actions.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              8. Hypocapnia triggers smooth muscle contraction that may bring on
              or exacerbate nausea, raise blood pressure, and reduce blood flow
              to other parts of the body such as the placenta. Attributing
              hypocapnic symptoms to pregnancy alone means overlooking practical
              solutions to performance deficits brought on by breathing habits.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              9. Hypocapnia causes bronchial muscle contraction and increased
              airway resistance that may trigger or exacerbate air hunger which
              may motivate and perpetuate vicious circle breathing habits.
              Overbreathing can perpetuate itself based on misguided
              self-fulfilling prophecy thinking.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              10. Hypocapnia triggers placental vasoconstriction (reduced blood
              flow), electrolyte shifts, and acid-base changes that may affect
              the health of the fetus, including increasing the likelihood of
              miscarriage. Compromised respiration during pregnancy may
              significantly affect the health of the fetus.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              11. Hypocapnia exacerbates pregnancy symptoms and associated
              physical and mental challenges. Unfortunately, this exacerbation
              is rarely identified as an interacting factor.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              12. Women may learn breathing habits during pregnancy that
              compromise health and performance postpartum, that is, habits that
              may unconsciously lead to hypocapnia after pregnancy and birth.
              Unfortunately, habits learned during pregnancy are rarely
              identified, managed, or replaced and are thus likely to remain in
              effect indefinitely.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              13. Standard breathing practices taught to pregnant women do not
              address hypocapnia, its effects, and how it can be managed. Much
              of what is taught by “experts” and learned by pregnant women is
              inconsistent with optimizing respiration during and after
              pregnancy.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              14. Blaming breathing-induced symptoms on pregnancy means being a
              victim of one’s breathing habits. It erodes self-confidence and
              may reinforce a sense of helplessness. Most women can easily learn
              to regulate CO2 during pregnancy and thus its associated symptoms
              and deficits.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              15. Breathing is taught to pregnant women everywhere, but
              unfortunately, very few birthing practitioners and pregnant women
              are aware of what constitutes optimal respiration. Even fewer of
              them know about the psychology of breathing, that is, the habits
              that mediate physical and mental changes during pregnancy.
              CapnoLearning offers a solution.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              <b>CapnoLearning</b>, learning to regulate carbon dioxide
              concentration, can significantly help pregnant women learn
              breathing habits that optimize respiration and significantly
              enhance health and performance during and after pregnancy.
              CapnoLearning™ is the application of breathing behavior analysis
              in the context of capnography technology where real-time changes
              in carbon dioxide concentration are monitored for identifying and
              editing breathing habits that may be compromising or optimizing
              respiration and its associated chemistry. (Litchfield & Reamer,
              2023)
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              The webinar instructors will present an overview of breathing
              behavior analysis specifically relevant to pregnancy, birth, and
              postpartum health. Participants will learn about how to identify
              dysfunctional breathing habits and to teach clients how to
              disengage them and learn new ones with CapnoTrainer technology.
              Relevant learning protocols for achieving optimal respiratory
              physiology FOR HEALTHY MOMS AND BABIES will be demonstrated live.
              Case examples will also be presented.
            </p>
            <p style={{ fontSize: "14px", fontWeight: "600",marginBottom:"1rem" }}>
              Webinar Objectives include:
            </p>
            <div style={{ fontSize: "14px",marginBottom:"1rem" }}>
              <li>introducing a psychophysiologic perspective of breathing</li>
              <li>
                {" "}
                understanding the important distinction between respiration and
                breathing
              </li>
              <li>
                {" "}
                learning about the chemistry of respiration and the psychology
                of breathing mechanics
              </li>
              <li>
                {" "}
                identifying situations, symptoms or complications commonly
                triggered by behavioral hypocapnia
              </li>
              <li>
                {" "}
                identifying, managing and overcoming dysfunctional breathing
                habits during pregnancy
              </li>
              <li>
                {" "}
                understanding how well intentioned breathwork interventions are
                often misguided
              </li>
              <li>
                {" "}
                demonstrating learning strategies for optimizing respiration
                during pregnancy
              </li>
              <li>
                {" "}
                cultivating awareness of breathing habits and their effects on
                health and performance during pregnancy
              </li>
              <li>
                demonstrating the use of educational capnometry during pregnancy
                and its role in maternal healthcare
              </li>
            </div>
            <p style={{ fontSize: "14px",marginBottom:"2rem" }}>
              <b>Professional Credit</b> requires completing a short
              multiple-choice test.
            </p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "600",
                textAlign: "center",
                marginBottom:"1rem"
              }}
            >
              Lisa V Kusch, M.S., B.Ed., CBBA, SBT
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              Ms. Kusch earned a Master of Science (MS) degree in Applied
              Breathing Sciences from the Graduate School of Behavioral Health
              Sciences (2020). She earned her Bachelor of Education degree
              (B.Ed.) in Psychology-Special Education from the University of
              Saskatchewan (1995), where she was a recipient of the prestigious
              Bates Award (1996) for outstanding curriculum development work.
              She is a Certified Breathing Behavior Analyst, an Applied
              Breathing Sciences Specialist, and a registered psychotherapist
              (Canada).
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              Ms. Kusch is the founder and CEO of Conspire Health (2023 -
              present), a cutting-edge clinic that provides breathing learning
              services from a psychophysiological perspective based on a
              client-centric sensory-motor practice paradigm. She is owner and
              operator of the Riverbank Mental Health Recovery Home (1998 -
              present) for adult women, pregnant women, and new moms
              experiencing severe mental illness. She is a faculty member of the
              Professional School of Behavioral Health Sciences (2022 -
              present). She is also currently a consultant to the Sports
              Psychology Department at the University of Alberta for preparing
              faculty and PhD students to deliver behavioral breathing programs
              to athletes.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              Ms. Kusch has more than 25 years of diverse experience as a
              birthing specialist, including having established the Birth
              Rhythms Pregnancy and Parenting Health Center (2008 - 2015) which
              provided educational curriculum and counseling services for new
              parents. Her Center partnered with CAPPA Canada, Ancient Art
              Midwifery Institute, Postpartum International, Saskatchewan
              Midwives Association, Pregnancy Crisis Centre, Planned Parenthood,
              Prairie Birth Collective, Maternal Health Consumer Group and other
              local organizations. She has also served as a faculty member for
              Numa Somatics (2019 -2023) and as a consultant to the Ob/Gyn
              Department of North York Hospital in Toronto (2023) for the
              development of breathing programs.
            </p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "600",
                textAlign: "center",
                marginBottom:"1rem"
              }}
            >
              Peter M. Litchfield, Ph.D.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              Dr. Litchfield earned his Ph.D. in experimental psychology from
              the University of Portland in 1972. He is currently President of
              the Professional School of Behavioral Health Sciences
              (2012-present), a licensed post-secondary school that provides
              professional certificate, certification, and diploma programs in
              respiratory psychophysiology and breathing behavior analysis for
              practitioners in healthcare, human service, and performance
              enhancement.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              Dr. Litchfield is CEO for Better Physiology Ltd (2000-present),
              the manufacturer of the CapnoTrainer®, instrumentation now used in
              more than 60 countries for identifying, disengaging, modifying,
              and learning breathing habits relevant to improving health and
              performance.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"1rem" }}>
              Dr. Litchfield is a Member of the American Psychological
              Association and the Society for Psychophysiological Research.
              Among his most recent publications is the book he coauthored with
              Sandra Reamer, CapnoLearning: An introductory Guide (2023). He has
              lectured for more than 50 years in person and by webinar on
              behavioral physiology, respiratory psychophysiology, breathing
              behavior analysis, and self-regulation science throughout North
              America and in Asia, Australia, Europe, and Latin America to
              diverse audiences, ranging from medical schools to corporate
              groups.
            </p>
            <p style={{ fontSize: "14px",marginBottom:"2rem" }}>
              <span
                style={{
                  color: "#383886",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Order the book
              </span>
              ,{" "}
              <b>
                <em>CapnoLearning: An Introductory Guide</em>
              </b>{" "}
              (2023), authored by Peter M. Litchfield and Sandra Reamer from the
              publisher at{" "}
              <a href="https://www.thebsj.org/" target="_blank">
                www.thebsj.org
              </a>
              .
            </p>
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  fontSize: "35px",
                  marginBottom: "5px",
                  color: "#383886",
                }}
              >
                COME, BE INSPIRED.
              </p>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                PROFESSIONAL SCHOOL OF BEHAVIORAL HEALTH SCIENCES
              </p>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  color: "#383886",
                  display: "inline",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={()=>setShowForm(true)}
              >
                CLICK HERE TO REGISTER
              </p>
              <p
                style={{
                  fontSize: "13px",
                  marginBottom: "2rem",
                  marginTop: "5px",
                }}
              >
                OR go to{" "}
                <a href="https://e-campus-registration.bp.edu/" target="_blank">
                  www.e-campus.bp.edu
                </a>
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <p style={{ fontSize: "30px", marginBottom: "25px" }}>
                <b style={{fontWeight : 600}}>
                  <em>You are invited!</em>
                </b>
              </p>
              <p
                style={{
                  fontSize: "35px",
                  marginBottom: "25px",
                  color: "#383886",
                }}
              >
                <b style={{fontWeight : 600}}>
                  <em>A Post Webinar Session</em>
                </b>
              </p>
              <p style={{ fontSize: "15px", marginBottom: "2px" }}>
                <em>Begins 15 minutes following the breathing science webinar</em>
              </p>
              <p style={{ fontSize: "15px", marginBottom: "2rem" }}>
                14:15 – 15:30
              </p>
              <p
                style={{
                  fontSize: "25px",
                  marginBottom: "2px",
                  fontWeight: "bold",
                }}
              >
                CapnoLearning Technology
              </p>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "2rem",
                  fontWeight: "bold",
                }}
              >
                The business of providing CapnoLearning services
              </p>
              <p style={{ fontSize: "14px", marginBottom: "2rem" }}>
                Are you interested in integrating CapnoLearning into your
                practice?
                <br />
                If so, this session will provide you with the details as to how
                to do so.
              </p>
              <p style={{ fontSize: "14px", marginBottom: "2rem" }}>
                with Peter M. Litchfield, Ph.D.
              </p>
              <p style={{ fontSize: "14px", marginBottom: "2rem" }}>
                Dr. Litchfield will describe the basics of CapnoTrainer
                technology
                <br />
                and how to get started in providing breathing services to
                pregnant women.
              </p>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "2px",
                  fontWeight: "bold",
                }}
              >
                Sponsored by Better Physiology Ltd
              </p>
              <p style={{ fontSize: "16px", marginBottom: "2px" }}>
                Manufacturer of the CapnoTrainer GO
              </p>
              <p style={{ fontSize: "16px", marginBottom: "2rem" }}>
                <a href="https://www.betterphysiology.com/" target="_blank">
                  www.betterphysiology.com
                </a>
              </p>

              {show && (
                <p
                  style={{
                    fontSize: "14px",
                    marginBottom: "2rem",
                    fontWeight: "600",
                    color: "#383886",
                    //   display: "inline",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShow(!show)}
                >
                  CLICK HERE TO HIDE.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PregencyBrochure;
